<template>
  <v-card-actions class="pa-4">
    <v-btn v-if="can_add_inspection" @click="$store.dispatch('inspectionDamageDialog/openEditInspection')">
      <v-icon>
        mdi-pencil
      </v-icon>
      <span class="ml-2">
        แก้ไข
      </span>
    </v-btn>
    <v-btn v-if="can_finish_inspection"
           :disabled="disableFinish"
           class="ml-auto"
           color="success darken-1 white--text"
           elevation="0"
           @click="doneInspection">
      <v-icon>
        mdi-clipboard-check
      </v-icon>
      <span class="ml-2">
        เสร็จสิ้นการสำรวจ
        <v-icon v-if="isLoading">
          mdi-refresh mdi-spin-fast
        </v-icon>
      </span>
    </v-btn>
    <v-dialog v-model="apiSetted"
              max-width="250"
              persistent>
      <v-card>
        <v-card-text class="my__v-card-text">
          <div v-if="isSuccess === true"
               class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              บันทึกข้อมูลสำเร็จ
            </div>
            <div class="progress-green">
              <div class="color-green"></div>
            </div>
          </div>
          <div v-if="isSuccess === false"
               class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              บันทึกข้อมูลไม่สำเร็จ
            </div>
            <div class="progress-red">
              <div class="color-red"></div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card-actions>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StepZeroActions',
  props: ['allComponentFinish'],
  data () {
    return {
      apiSetted: false,
      isSuccess: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', { bridgeId: 'bridgeId', inspectionType: 'inspectionType' }),
    ...mapGetters('bridgeDetail', { canEdit_permission: 'canEdit_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection', can_finish_inspection: 'can_finish_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canEdit_permission
    },
    disableFinish () {
      return (String(this.inspectionType) === '2' && !this.allComponentFinish) || this.isLoading
    }
  },
  methods: {
    async doneInspection () {
      this.isLoading = true
      this.isSuccess = await this.$store.dispatch('inspectionDamageDialog/setInspectionDone')
      this.apiSetted = true
      this.isLoading = false
      setTimeout(() => {
        this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', false)
      }, 3000)
    }
  }
}
</script>

<style scoped>

</style>
